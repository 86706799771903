/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Axios from 'axios';
export default {
  name: "rx-portal",
  props: {
    portal: Object
  },
  data() {
    return {
      ywcl: true,
      news: [],
      colList: [],
      taskCount: 0,
      agents: 0,
      gridNumZBGL: ""
    };
  },
  methods: {
    // gridNumZB(){
    // 	let url = _baseUrl +`/mobile/ywgz/getTbXxTotalCount.do`
    // 	Axios.post(url).then((res)=>{
    // 		this.gridNumZBGL = res.data.total
    // 	})
    // },
    portalJump(item) {
      // console.log(item)
      var type = item.type;
      var name = "";
      var params = {};
      if (type) {
        if (type == 'mySolution') {
          // name = "startForm";
          // 	params = {
          // 		solId: item.id
          // 	}
          switch (item.title) {
            case "发文流程":
              name = "lcfqFwlc";
              params = {
                solId: item.id
              };
              break;
            case "事假请假":
              name = "lcfqSjqj";
              params = {
                solId: item.id
              };
              break;
            case "普通请假":
              name = "lcfqPtqj";
              params = {
                solId: item.id
              };
              break;
            case "年休请假":
              name = "lcfqNxqj";
              params = {
                solId: item.id
              };
              break;
            case "人员入职":
              name = "lcfRyrz";
              params = {
                solId: item.id
              };
              break;
            case "人员离职":
              name = "lcfqRylz";
              params = {
                solId: item.id
              };
              break;
            case "人员调动":
              name = "lcfqRydd";
              params = {
                solId: item.id
              };
              break;
            case "用车申请":
              name = "lcfqYcsq";
              params = {
                solId: item.id
              };
              break;
            case "用品采购":
              name = "lcfqYpcg";
              params = {
                solId: item.id
              };
              break;
            case "用印审批":
              name = "lcfqYyqy", params = {
                solId: item.id
              };
              break;
            default:
              name = "startForm";
              params = {
                solId: item.id
              };
          }
          // if(item.title == "发文流程"){
          // 	name="lcfqFwlc";
          // 	params = {
          // 		solId: item.id
          // 	}
          // }else{
          // 	name = "startForm";
          // 	params = {
          // 		solId: item.id
          // 	}
          // }
        } else if (type == 'solTask') {
          name = "solTask";
          params = {
            solId: item.id
          };
        } else if (type == 'customList') {
          name = "customList";
          params = {
            alias: item.key
          };
        } else if (type == 'customForm') {
          name = "customForm";
          params = {
            boKey: "-1",
            alias: "-1",
            addAlias: item.alias,
            detailAlias: "-1",
            //新增表单
            id: "-1",
            readonly: false,
            type: 'add',
            relField: 0
          };
        }
        this.jump(name, params);
      }
    },
    processClick(item) {
      this.jump(item.routeName);
    },
    getNewsDetals(newsId) {
      if (!newsId) return;
      this.$router.push({
        name: 'insNewsDetail',
        params: {
          newsId: newsId,
          isNewsId: true
        }
      });
    },
    countTask() {
      var url = _baseUrl + "/mobile/bpm/getCounts.do";
      var param = "pageSize=1";
      this.$ajax.post(url, param).then(res => {
        if (res.data.task) {
          this.taskCount = res.data.task;
        }
        if (res.data.agents) {
          this.agents = res.data.agents;
        }
      });
    },
    init() {
      var html = this.portal.layoutHtml;
      console.log(html);
      if (html) {
        this.colList = JSON.parse(html);
        // //改：加上业务处理
        // let ywcl = {type:"ywcl"};
        // this.colList.push(ywcl)
      }

      this.countTask();
    },
    //------------------自己主页代码--------------------------
    onYWdaiban() {
      this.$router.push("/yewhome");
    }
    // onZBguanli(){
    // 	this.$router.push("/ywLIst")
    // }
  },

  created() {
    this.init();
    // this.gridNumZB();
  },

  activated() {
    this.init();
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import router from '@/router/index';
import { RxUtil } from "@/assets/util.js";
export default {
  name: 'my-info',
  data() {
    return {
      osUser: {},
      mainDepName: '',
      photo: "",
      statuColor: "#f05654" //#9ed048,
    };
  },

  created() {
    var push_switch = RxUtil.getCache("push_switch");
    this.statuColor = push_switch == "on" ? "#9ed048" : "#f05654";
    this.getCurrentUser(user => {
      this.initUser(user);
    });
  },
  methods: {
    initUser(user) {
      this.osUser = user.osUser;
      this.mainDepName = user.mainDepName;
    },
    loginOut() {
      var url = _baseUrl + '/logoutApp.do';
      this.$ajax.get(url).then(response => {
        if (response.data.success == true) {
          setTimeout(function () {
            router.push({
              name: 'login',
              params: {
                source: 'loginOut'
              }
            });
          }, 1000);
        }
      });
    },
    getFilePath(fileId) {
      return this.getRealUrl() + "/sys/core/file/previewFile.do?fileId=" + fileId;
    },
    toScanCode() {
      router.push({
        name: 'qrcode'
      });
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import router from '@/router/index';
import listItem from '@/components/common/ListItem';
import rxNews from '@/components/common/rxNews';
Vue.component(listItem.name, listItem);
Vue.component(rxNews.name, rxNews);
export default {
  name: 'ins-news',
  data() {
    return {
      newsList: [],
      Lock: false
    };
  },
  props: {
    bv: Boolean
  },
  /*watch:{
  	lockScroller:{
  		handler:function(vel){
  			this.Lock = !vel;
  			console.log(vel)
  		}
  	}
  },*/
  methods: {
    getAllNews() {
      var url = _baseUrl + "/mobile/oa/getAllNews.do";
      this.$ajax({
        url: url,
        method: 'POST'
      }).then(response => {
        this.newsList = response.data.data;
      }).catch(error => {
        console.log(error);
      });
    },
    getNewsDetals(newsItem) {
      this.$router.push({
        name: 'insNewsDetail',
        params: {
          'newsItem': newsItem
        }
      });
    },
    refresh() {
      console.log(this.Lock);
    }
  },
  created() {
    this.getAllNews();
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: 'rx-news',
  props: {
    newsItem: {
      type: Object
    }
  },
  methods: {
    imgReqPath(val) {
      if (val) {
        let url = this.getRealUrl() + "/sys/core/file/previewImage.do?fileId=" + val;
        return url;
      }
    },
    getAbstractNews(content) {
      if (!content) {
        return;
      }
      if (content.length > 100) {
        let subContent = content.substr(content.indexOf('>') + 1, 100);
        return subContent + "...";
      }
      return content;
    }
  }
};
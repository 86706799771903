/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from "vue";
import router from "@/router/index";
import rxNotice from "@/components/common/portal/rxNotice";
import rxPortal from "@/components/common/portal/rxPortal";
Vue.component(rxNotice.name, rxNotice);
Vue.component(rxPortal.name, rxPortal);
export default {
  name: "work-space",
  data() {
    return {
      portalList: [],
      portalLength: 1,
      horizontal: true,
      activeIndex: 0
    };
  },
  created() {
    this.init();
  },
  computed: {
    count() {
      return this.$store.state.pageModule.countNum; //读取Vuex里面的数据
    },

    isLogin() {
      return this.$store.state.userModule.isLogin;
    }
  },
  methods: {
    init() {
      this.listPortal();
    },
    listPortal() {
      var params = "";
      var url = _baseUrl + "/oa/info/insPortalDef/mobilePortalHtml.do";
      this.$ajax.get(url).then(res => {
        if (res && res.data && res.data.success) {
          this.portalList = res.data.data;
          // console.log(this.portalList)
          this.portalLength = this.portalList.length;
        }
      });
    },
    handleActive(index) {
      this.activeIndex = index;
    }
  },
  activated() {
    this.init();
  },
  watch: {
    isLogin: function (val, oldVal) {
      this.init();
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
export default {
  name: "rx-notice",
  props: {
    num: Number
  },
  data() {
    return {
      news: []
    };
  },
  methods: {
    getAllNews() {
      if (!this.num) return;
      var url = _baseUrl + "/oa/info/insNews/listDataJson.do";
      var params = "pageIndex=0&pageSize=" + this.num;
      this.$ajax.post(url, params).then(response => {
        this.news = response.data.data;
      }).catch(function (error) {});
    },
    getNewsDetals(newsItem) {
      this.$router.push({
        name: 'insNewsDetail',
        params: {
          'newsItem': newsItem
        }
      });
    },
    refresh() {
      console.log(this.Lock);
    }
  },
  created() {
    this.getAllNews();
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import WorkSpace from '@/components/oa/WorkSpace';
import insNews from '@/components/oa/InsNews';
import myInfo from '@/components/oa/MyInfo';
import Move from '@/components/Move';
import { RxUtil } from "@/assets/util.js";
export default {
  data() {
    return {
      iconList: {
        icon: {
          1: "shouye",
          //					2:'notice',
          //					3:'department',
          2: 'wode'
        },
        title: {
          1: '工作台',
          //					2:'公告',
          //					3:'通讯录',
          2: '我'
        }
      }
    };
  },
  components: {
    WorkSpace,
    insNews,
    myInfo,
    Move
  },
  methods: {
    registCid() {
      let summitCidTag = RxUtil.getCache("summitCidTag");
      if (summitCidTag == "0") {
        if (!_openMsgPush) return;
        let cid = plus.push.getClientInfo().clientid;
        let mobileType = plus.os.name;
        let url = _baseUrl + "/mobile/bpm/registCid.do";
        this.$ajax({
          url: url,
          method: 'post',
          params: {
            cid: cid,
            mobileType: mobileType
          }
        }).then(response => {
          if (response.data.success) {
            RxUtil.setCache("summitCidTag", "1");
            RxUtil.setCache("push_switch", "on");
            console.info(response.data.message);
            return;
          }
          RxUtil.setCache("push_switch", "off");
          console.info(response.data.message);
        }).catch(error => {
          console.error(error);
        });
      }
    }
  },
  created() {
    this.getCurrentUser();
    //用户绑定CID,CID绑定机型(苹果、安卓、其他)
    this.registCid();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$refs.move.obtainIndex(to.fullPath);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.layer > from.meta.layer) {
      this.$refs.move.keepIndex(from.fullPath);
    } else {
      this.$refs.move.keepIndex(from.fullPath, "empty");
    }
    next();
  }
};